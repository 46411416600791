import React from 'react'
import Klarna from '../pics/partners/klarna.png'

const Installments = () => {
	return (<>
			<h3>Instalment payments are available at the checkout</h3>
			<p><span className='highlighter'>Buy now, pay later</span>. Provided by <a href='https://www.klarna.com/' target='blank'><img className="inlineImages" src={Klarna} alt='klarna logo'/></a>
			</p>
			</>
	)
}

export default Installments